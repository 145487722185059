import { TypeManagerDecorator } from "../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AdmitService, PoolLiteTO } from "./admit.service";
import { Integration, IntegrationService, IntegrationTypes } from "../integrations/integration.service";
import { S25Util } from "../../util/s25-util";
import { EventService } from "../../services/event.service";
import { CustomAttributes } from "../../pojo/CustomAttributes";

@TypeManagerDecorator("s25-ng-admit-action-link")
@Component({
    selector: "s25-ng-admit-action-link",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        @if (init) {
            @if (!showLink) {
                @if (preAction === "createPool") {
                    <div class="c-margin-bottom--single">Manage registrants in your own CRM</div>
                    <label class="ngBlock c-margin-bottom--single"
                        >Pool Name (Required): <input class="c-input" type="text" [(ngModel)]="poolName"
                    /></label>
                    <label class="ngBlock c-margin-bottom--single"
                        >Select a Parent Pool (Optional):
                        <select [(ngModel)]="parentPoolId">
                            @for (pool of pools; track pool.id) {
                                <option [ngValue]="pool.id">{{ pool.name }}</option>
                            }
                        </select>
                    </label>
                }

                @if (!integration && preActionProcessing) {
                    <div>
                        Creating your new CRM instance! This may take a few minutes. When everything is ready, a link to
                        the CRM will appear here.
                    </div>
                }

                <s25-ng-button [type]="'primary'" [onClick]="runPreAction">{{ preActionName }}</s25-ng-button>
            } @else {
                <a href="{{ redirectLink }}" [target]="target" data-nav-link="crm" role="option">Go to CRM</a>
            }
        }
    `,
})
export class AdmitActionLinkComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    @Input({ required: true }) preAction: "createInstance" | "createPool";
    @Input() eventId?: number;

    init = false;
    showLink = false;
    preActionProcessing = false;
    eventAlreadyLinked = false;
    parentPoolId: number;
    integration: Integration;
    pools: PoolLiteTO[] = [];
    poolName: "";
    preActionName = "";
    redirectLink = "";
    target = "self";

    runPreAction = async () => {
        if (this.preAction === "createPool" && !this.poolName) {
            alert("Please enter a pool name.");
            return;
        }

        try {
            this.preActionProcessing = true;
            this.cd.detectChanges();
            if (!this.integration) {
                this.integration = await AdmitService.createIntegration();
            }
            if (this.preAction === "createPool" && this.eventId && this.poolName) {
                await AdmitService.createPool(this.eventId, this.poolName, this.parentPoolId);
            }
            this.showLink = true;
            this.preActionProcessing = false;
            this.cd.detectChanges();
        } catch (ex) {
            this.preActionProcessing = false;
            this.showLink = false;
            this.cd.detectChanges();
            S25Util.showError(ex);
        }
    };

    async ngOnInit() {
        this.redirectLink = AdmitService.getRedirectUrl();
        if (this.preAction === "createInstance") {
            this.preActionName = "Create CRM Instance";
        } else if (this.preAction === "createPool") {
            this.preActionName = "Create CRM Pool";
            this.target = "_blank";
            let event = await EventService.getEventInclude(this.eventId, ["attributes"]);
            this.eventAlreadyLinked = !!event.custom_attribute.filter(
                (attr) => attr.attribute_id === CustomAttributes.system.AdmitPoolId.id,
            ).length;
            if (!this.eventAlreadyLinked) {
                this.pools = await AdmitService.getPools();
                this.pools.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            }
        }

        this.integration = await IntegrationService.getIntegration(IntegrationTypes.admit);

        // only show the link if the integration is enabled and the pre-action is to create an instance
        // or if the event is already linked to a pool
        // bc if they are creating a pool, the user must name the pool and optionally choose a parent pool first
        this.showLink = !!this.integration && (this.preAction === "createInstance" || this.eventAlreadyLinked);

        this.init = true;
        this.cd.detectChanges();
    }
}
