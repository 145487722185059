import { NgModule } from "@angular/core";
import { AdmitActionLinkComponent } from "./admit.action.link.component";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [AdmitActionLinkComponent],
    imports: [S25ButtonComponent, FormsModule],
    providers: [AdmitActionLinkComponent],
    exports: [AdmitActionLinkComponent],
})
export class AdmitModule {
    constructor() {}
}
