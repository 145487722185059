import { DataAccess } from "../../dataaccess/data.access";
import { Integration } from "../integrations/integration.service";
import { TelemetryService } from "../../services/telemetry.service";

export interface PoolLiteTO {
    id?: number;
    parent_id?: number;
    name?: string;
    type?: "application" | "profile";
}

export class AdmitService {
    public static async createIntegration(): Promise<Integration> {
        TelemetryService.sendWithSub("SysSettings", "Integrations", "AdmitOn");
        return DataAccess.post("/admit/inst.json");
    }

    // note: adds a numeric event custom attr on the event with the Admit pool id as a value
    public static async createPool(eventId: number, poolName: string, parentPoolId: number) {
        TelemetryService.send("Admit", "CreatePool");
        let url = `/admit/pool.json?eventId=${eventId}&poolName=${encodeURIComponent(poolName)}`;
        if (parentPoolId) {
            url += `&parentPoolId=${parentPoolId}`;
        }
        return DataAccess.post(url);
    }

    public static async getPools(): Promise<PoolLiteTO[]> {
        return DataAccess.get("/admit/pools.json");
    }

    public static getRedirectUrl() {
        return DataAccess.getUrl("/redirect/app?app=admit");
    }
}
